<template>
  <NThemeEditor v-if="enableThemeEditor" ref="themeEditor">
    <NConfigProvider
      :theme-overrides="mergedThemeOverrides"
      :locale="enAu"
      :date-locale="dateEnAu"
      preflight-style-disabled
    >
      <slot/>
    </NConfigProvider>
  </NThemeEditor>
  <NConfigProvider
    v-else
    :theme-overrides="mergedThemeOverrides"
    :locale="enAu"
    :date-locale="dateEnAu"
    preflight-style-disabled
  >
    <slot/>
  </NConfigProvider>
</template>

<script setup lang="ts">
import {merge} from "lodash";
import {NConfigProvider, NThemeEditor} from "naive-ui";
import {themeOverrides} from "@/ThemeOverrides.ts";
import enAu from "@core/locale/enAu.ts";
import dateEnAu from "@core/locale/dateEnAu.ts";
import {enableThemeEditor} from "@core/stores/useMyProfile.ts";
import {computed, ref, watch} from "vue";
import customOverrides from "@/custom-overrides.json";

const mergedThemeOverrides = computed(() => {
  return merge(themeOverrides, customOverrides);
});
const themeEditor = ref();
watch(enableThemeEditor, async (value: boolean) => {
  if (value) {
    if (themeEditor.value?.overrides) {
      themeEditor.value.overrides = mergedThemeOverrides.value;
    }
  }
});
</script>
