import {GlobalThemeOverrides} from "naive-ui";

export const themeOverrides: GlobalThemeOverrides = {
    common: {
        baseColor: "#FFF",

        // primaryColor: '#044cd0',// #18a058
        // primaryColorHover: '#0455e9',// #36ad6a
        // primaryColorPressed: '#033a9e',// #0c7a43
        // primaryColorSuppl: '#0455e9',// 36ad6a
        //
        // infoColor: '#09aeae',// #2080f0
        // infoColorHover: '#0ac6c6',// #4098fc
        // infoColorPressed: '#067e7e',// #1060c9
        // infoColorSuppl: '#0ac6c6',// #4098fc

        fontFamily: 'v-sans, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
        fontFamilyMono: 'v-mono, SFMono-Regular, Menlo, Consolas, Courier, monospace',
        fontWeight: '400',
        fontWeightStrong: '500',
        cubicBezierEaseInOut: 'cubic-bezier(.4, 0, .2, 1)',
        cubicBezierEaseOut: 'cubic-bezier(0, 0, .2, 1)',
        cubicBezierEaseIn: 'cubic-bezier(.4, 0, 1, 1)',
        borderRadius: '3px',
        borderRadiusSmall: '2px',
        fontSize: '14px',// '14px',
        fontSizeMini: '12px',// '12px',
        fontSizeTiny: '12px',// '12px',
        fontSizeSmall: '14px',// '14px',
        fontSizeMedium: '14px',// '14px',
        fontSizeLarge: '15px',// '15px',
        fontSizeHuge: '16px',// '16px',
        lineHeight: '1.6',
        heightMini: '10px',// '16px',
        heightTiny: '16px',// '22px',
        heightSmall: '22px',// '28px',
        heightMedium: '24px',// '34px',
        heightLarge: '26px',// '40px',
        heightHuge: '28px',// '46px'
    },
    Button: {
        paddingTiny: '0 3px',// '0 6px',
        paddingSmall: '0 4px',// '0 10px',
        paddingMedium: '0 5px',// '0 14px',
        paddingLarge: '0 6px',// '0 18px',
        paddingRoundTiny: '0 10px',// '0 10px',
        paddingRoundSmall: '0 14px',// '0 14px',
        paddingRoundMedium: '0 18px',// '0 18px',
        paddingRoundLarge: '0 22px',// '0 22px',
        iconMarginTiny: '3px',// '6px',
        iconMarginSmall: '4px',// '6px',
        iconMarginMedium: '5px',// '6px',
        iconMarginLarge: '6px',// '6px',
        iconSizeTiny: '14px',// '14px',
        iconSizeSmall: '16px',// '18px',
        iconSizeMedium: '18px',// '18px',
        iconSizeLarge: '20px',// '20px',
        rippleDuration: '.6s',
        heightTiny: '18px',
        heightSmall: '20px',
        heightMedium: '24px',
        heightLarge: '28px',
        fontSizeSmall: '12px',
        fontSizeMedium: '12px',
        fontSizeLarge: '12px',
        fontWeight: 480,
    },
    Form: {
        feedbackPadding: '4px 0 0 2px',
        feedbackHeightSmall: '24px',
        feedbackHeightMedium: '24px',
        feedbackHeightLarge: '26px',
        feedbackFontSizeSmall: '12px',
        feedbackFontSizeMedium: '12px',
        feedbackFontSizeLarge: '14px',
        labelFontSizeLeftSmall: '12px',
        labelFontSizeLeftMedium: '12px',
        labelFontSizeLeftLarge: '14px',
        labelFontSizeTopSmall: '12px',
        labelFontSizeTopMedium: '12px',
        labelFontSizeTopLarge: '14px',
        labelHeightSmall: '24px',
        labelHeightMedium: '24px',
        labelHeightLarge: '26px',
        labelPaddingVertical: '0 0 3px 2px',// '0 0 6px 2px',
        labelPaddingHorizontal: '0 12px 0 0',
        labelTextAlignVertical: 'left',
        labelTextAlignHorizontal: 'right',
        labelFontWeight: '500',
    },
    Typography: {
        headerFontSize1: '24px',// '30px',
        headerFontSize2: '22px',// '22px',
        headerFontSize3: '20px',// '18px',
        headerFontSize4: '18px',// '16px',
        headerFontSize5: '16px',// '16px',
        headerFontSize6: '14px',// '16px',
        headerMargin1: '0 0 0 0',// '28px 0 20px 0'
        headerMargin2: '0 0 0 0',// '28px 0 20px 0'
        headerMargin3: '0 0 0 0',// '28px 0 20px 0'
        headerMargin4: '0 0 0 0',// '28px 0 18px 0'
        headerMargin5: '0 0 0 0',// '28px 0 18px 0'
        headerMargin6: '0 0 0 0',// '28px 0 18px 0'
        headerPrefixWidth1: '16px',
        headerPrefixWidth2: '16px',
        headerPrefixWidth3: '12px',
        headerPrefixWidth4: '12px',
        headerPrefixWidth5: '12px',
        headerPrefixWidth6: '12px',
        headerBarWidth1: '4px',
        headerBarWidth2: '4px',
        headerBarWidth3: '3px',
        headerBarWidth4: '3px',
        headerBarWidth5: '3px',
        headerBarWidth6: '3px',
        pMargin: '16px 0 16px 0',
        liMargin: '.25em 0 0 0',
        olPadding: '0 0 0 2em',
        ulPadding: '0 0 0 2em'
    },
    Card: {
        paddingSmall: '12px 16px 12px',
        paddingMedium: '19px 24px 20px',
        paddingLarge: '23px 32px 24px',
        paddingHuge: '27px 40px 28px',
        titleFontSizeSmall: '16px',
        titleFontSizeMedium: '18px',
        titleFontSizeLarge: '20px',
        titleFontSizeHuge: '22px',
        closeIconSize: '18px',
        closeSize: '22px'
    },
    Descriptions: {
        thPaddingBorderedSmall: '8px 12px',
        thPaddingBorderedMedium: '12px 16px',
        thPaddingBorderedLarge: '16px 24px',
        thPaddingSmall: '0',
        thPaddingMedium: '0',
        thPaddingLarge: '0',
        tdPaddingBorderedSmall: '8px 12px',
        tdPaddingBorderedMedium: '12px 16px',
        tdPaddingBorderedLarge: '16px 24px',
        tdPaddingSmall: '0 0 8px 0',
        tdPaddingMedium: '0 0 12px 0',
        tdPaddingLarge: '0 0 16px 0',
        fontSizeSmall: '12px',
        fontSizeMedium: '12px',
        fontSizeLarge: '14px',
        thFontWeight: '500',
    },
    Icon: {},
    Menu: {fontSize: '12px'},
    DataTable: {
        fontSizeSmall: '12px',
        fontSizeMedium: '12px',
        fontSizeLarge: '14px',
        fontWeight: '480',
        fontWeightStrong: '500',
    },
    Divider: {},
    Table: {
        fontSizeSmall: '12px',
        fontSizeMedium: '12px',
        fontSizeLarge: '14px',
    },
    Tag: {
        fontSizeSmall: '12px',
        fontSizeMedium: '12px',
        fontSizeLarge: '14px',
    },
    Input: {
        fontSizeSmall: '12px',
        fontSizeMedium: '12px',
        fontSizeLarge: '14px',
    },
    InputNumber: {
        fontSizeSmall: '12px',
        fontSizeMedium: '12px',
        fontSizeLarge: '14px',
    },
    Select: {},
    InternalSelectMenu: {
        optionFontSizeSmall: '12px',
        optionFontSizeMedium: '12px',
        optionFontSizeLarge: '14px',
    },
    Popover: {
        fontSize: '12px',
    },
    Empty: {
        fontSizeSmall: '12px',
        fontSizeMedium: '12px',
        fontSizeLarge: '14px',
    },
    InternalSelection: {
        fontSizeSmall: '12px',
        fontSizeMedium: '12px',
        fontSizeLarge: '14px',
    },
    Alert: {
        fontSize: '12px',
        lineHeight:'1.2',
        iconSize:'16px',
        iconMargin: '8px 4px 0 8px',
        iconMarginRtl:'4px 8px 0 8px',
        padding:'8px',
    }
}
