<template>
  <NPopover :overlap="true" :show-arrow="false" :disabled="restItem?.length <=1">
    <template #trigger>
      <NText v-text="firstItem" :type="props.type"/>
    </template>
    <div v-for="err in restItem">
      <NText v-text="err" :type="props.type"/>
    </div>
  </NPopover>
</template>


<script setup lang="ts">
import {NPopover, NText} from 'naive-ui';
import {computed, unref} from "vue";

export interface Props {
  feedback?: Array<string> | string,
  type?: string,
}

const props = withDefaults(defineProps<Props>(), {
  type: 'error',
});


const firstItem = computed(() => {
  if (Array.isArray(props.feedback)) {
    return unref(props.feedback)[0];
  }
  return unref(props.feedback);
});
const restItem = computed(() => {
  if (Array.isArray(props.feedback)) {
    return unref(props.feedback);
  }
  return [];
});
</script>

<style scoped lang="scss">

</style>
