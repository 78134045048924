import {RouteRecordRaw} from "vue-router";
import {createMenuOption} from "@core/utils/render.ts";
import {DashboardIcon} from '@core/components/icons.js';
import {MenuOption} from "naive-ui";
import {MenuDividerOption, MenuGroupOption} from "naive-ui/es/menu/src/interface";
import {Component} from "@vue/runtime-core";

const RouteNames = {
    Index: 'dashboard',
};

export const Routes = {
    Index: {
        name: RouteNames.Index,
        label: 'Dashboard',
        icon: DashboardIcon,
        to: {name: RouteNames.Index},
    }
};

export const routes = {
    index: {
        path: '',
        name: 'dashboard',
        component: () => import('@admin/components/dashboard/Dashboard.vue'),
        icon: DashboardIcon,
        meta: {title: 'Dashboard'},
    } as RouteRecordRaw & {
        icon: Component
    },
    workInProgress: {
        path: 'work-in-progress',
        name: 'dash_work_in_progress',
        component: () => import('@admin/components/dashboard/DashInProgress.vue'),
    } as RouteRecordRaw,
    userStatistics: {
        path: 'user-statistics',
        name: 'dash_user_statistics',
        component: () => import('@admin/components/dashboard/UserStatistics.vue'),
    } as RouteRecordRaw,
    accounts: {
        path: 'accounts',
        name: 'dash_accounts',
        component: () => import('@admin/components/dashboard/DashAccounts.vue'),
    }
};
export const getMenuOption = (children: (MenuOption | MenuGroupOption | MenuDividerOption)[] | RegExp | boolean = true) => {
    return createMenuOption(routes.index.name, 'Dashboard', routes.index.icon, routes.index.name, children);
}

export default {
    path: 'dashboard',
    children: [
        {
            ...routes.index,
            children: [
                routes.workInProgress,
                routes.userStatistics,
                routes.accounts,
            ]
        }
    ],
} as RouteRecordRaw;
