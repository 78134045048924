<template>
  <NFormItem :label="label" :path="path" :required="required" :rule="rule" :show-feedback="Boolean(feedback)"
             :size="size" :validation-status="validationStatus">
    <template #label>
      <span>{{ label }}</span>
      <span>
        <NTooltip v-if="tip" trigger="hover">
            {{ tip }}
            <template #trigger>
                <NIcon :component="Question" style="padding-left: 4px; vertical-align: middle"/>
            </template>
        </NTooltip>
      </span>
    </template>
    <slot></slot>
    <template #feedback>
      <FormItemFeedback v-if="feedback" :feedback="feedback"/>
    </template>
  </NFormItem>
</template>
<script lang="ts" setup>
import {FormItemRule, NFormItem, NIcon, NTooltip} from 'naive-ui';
import {Question} from '@vicons/fa';
import FormItemFeedback from "@core/components/form/FormItemFeedback.vue";
import {computed, toRefs, unref} from "vue";

export interface Props {
  label?: string,
  path?: string,
  feedback?: Array<string> | string,
  tip?: string | null,
  size?: "medium" | "small" | "large",
  rule?: FormItemRule | FormItemRule[],
  required?: boolean,
}

const props = withDefaults(defineProps<Props>(), {
  tip: null,
  size: 'medium'
});

const {feedback} = toRefs(props);

const validationStatus = computed(() => {
  const error = unref(feedback);

  if (!error || (Array.isArray(error) && error.length === 0)) {
    return;
  }

  return 'error';
});

</script>

<style lang="scss" scoped>

</style>
