import {logoutAction} from "@admin/composable/logoutAction.ts";
import {executeContext} from "@core/composable/useAsyncCallback.ts";
import {IModuleType} from "@core/router/types.ts";
import {routes as DashboardRoutes} from '@routes/dashboard.ts';
import {castArray} from "lodash";
import {NSpin} from "naive-ui";
import {h} from "vue";
import {createRouter, createWebHistory, RouteLocationNormalized, RouteRecordRaw} from 'vue-router';

export const basePath = '/';

const modules = import.meta.glob<IModuleType>('./modules/**/*.ts');

const routeModules = Object.values(modules).map(async (module) => {
  const mod = (await module()).default;
  if (mod) {
    return castArray(mod);
  }
});

export default (async () => {
  let routes = (await Promise.all(routeModules))
    .reduce((previousValue: any[], currentValue: any[] | undefined) => {
    if (!currentValue) {
      return previousValue;
    }

    return [...previousValue, ...currentValue];
  }, []) as Readonly<RouteRecordRaw[]>;

  const router = createRouter({
    history: createWebHistory(),
    linkExactActiveClass: 'active',
    linkActiveClass: 'active',
    routes: [
      {
        path: basePath,
        redirect: {name: DashboardRoutes.index.name},
        children: [
          ...routes,
          {
            path: 'logout',
            name: 'logout',
            component: h(NSpin, {show: true, style: {width: '100%', height: '100%'}}),
            beforeEnter: async (_, from) => {
              await logoutAction(router, from);
            }
          },
        ],
      },
      (await import('@admin/routes/not-found.ts')).routes,
    ]
  });

  router.beforeResolve((to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    executeContext(to, from);
  });

  return router;
});
