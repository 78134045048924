import axios, {RawAxiosRequestHeaders} from 'axios';

const bearer = document.head.querySelector('meta[name=access-token]')?.attributes.getNamedItem('content')?.value;
const csrf = document.head.querySelector('meta[name=csrf-token]')?.attributes.getNamedItem('content')?.value;

const headers = {
  'X-Requested-With': 'XMLHttpRequest',
} as RawAxiosRequestHeaders;

if (bearer) {
  headers['Authorization'] = `Bearer ${bearer}`;
}

if (csrf) {
  headers['X-CSRF-TOKEN'] = csrf;
}

const request = axios.create({
  headers,
  baseURL: '/api/',
});

export const webRequest = axios.create({headers});
export const apiRequest = axios.create({headers, baseURL: '/api/',});

export const setAuthorizationToken = (token: any) => {
  request.defaults.headers.common['Authorization'] = token;
};

function handleAxiosError(error: any) {
  let message = 'Unknown Request Error';
  const status = error?.response?.status;
  switch (status) {
    case 400:
      message = 'Bad Request Error';
      break;
    case 404:
      message = 'Not Found Error';
      break;
    case 500:
      message = 'Internal Server Error';
      break;
    case 403:
      message = 'Forbidden Error';
      break;
    case 409:
      message = 'Conflicting Error';
      break;
    case 422:
      return error?.response?.data;
    default:
      break;
  }
  return {message, status};
}

// @ts-ignore
function handleUnexpectedError(error: any) {
  return {message: 'Unknown Error'};
}

request.interceptors.response.use(
  (response) => response,
  (error) => {
    if (axios.isAxiosError(error)) {
      return Promise.reject(handleAxiosError(error));
    }

    return Promise.reject(handleUnexpectedError(error));  }
);

export default request;
