import InputDateRange from "@core/components/form/InputDateRange.vue";
import {h, reactive, Ref, VNodeChild, watch} from "vue";
import {DataTableBaseColumn, MenuOption, NIcon, NSelect, SelectGroupOption, SelectOption} from "naive-ui";
import {Component} from "@vue/runtime-core";
import {RouteRecordName, RouterLink} from "vue-router";
import {MenuDividerOption, MenuGroupOption} from "naive-ui/es/menu/src/interface";
import {isEmpty, isRegExp, some} from "lodash";
import FormItem from "@core/components/form/FormItem.vue";
import {parseDate} from "@core/utils/date.ts";
import {zone} from "@core/settings/dateTimeSettings.ts";

export function renderIcon(Icon: Component, props: any | null = null) {
    return h(NIcon, props, {
        default: () => h(Icon)
    });
}

export const createMenuOption = (key: RouteRecordName | string | undefined, label: string,
                                 icon?: Component | null,
                                 routeName: RouteRecordName | string | undefined = '',
                                 children: (MenuOption | MenuGroupOption | MenuDividerOption)[] | RegExp | boolean = true): MenuOption => {
    const option: MenuOption = {
        key: key as string,
        icon: !!icon ? () => renderIcon(icon) : undefined,
        label: () => routeName ? h(RouterLink, {to: {name: routeName as string}}, {
            default: () => label
        }) : label,
    };
    if (Array.isArray(children)) {
        option.children = children;
        option.show = some(children, 'show');
    } else {
        option.show = isRegExp(children) ? children.test(label) : children;
    }
    return option;
}
export const createFilterColumn = (option: {
    title: string,
    key: string,
    value: Ref<(string | number)[]>,
    options: Ref<any[]>,
    search?: (text: string) => Promise<void>
    valueField: string,
    labelField: string,
    sorter: boolean,
    loading: Ref<boolean>,
    width?: number | string,
    render?: (rowData: any, rowIndex: number) => VNodeChild,
    renderLabel?: (option: SelectOption | SelectGroupOption, selected: boolean) => VNodeChild,
    colSpan?: (rowData: object, rowIndex: number) => number,
    filter?: (pattern: string, option: any) => boolean,
}, onUpdate: (value: (string | number)[]) => void) => {
    const column: DataTableBaseColumn = reactive<DataTableBaseColumn>({
        title: option.title,
        key: option.key,
        sorter: option.sorter,
        width: option.width,
        filter: true,
        filterOptionValue: null,
        renderFilterMenu:
            () => h(FormItem,
                {
                    style: 'width:200px',
                    showFeedback: false,
                    showLabel: false
                },
                () => h(NSelect,
                    {
                        value: option.value.value,
                        options: option.options.value,
                        valueField: option.valueField,
                        labelField: option.labelField,
                        renderLabel: option.renderLabel,
                        consistentMenuWidth: true,
                        multiple: true,
                        filterable: true,
                        clearable: true,
                        showArrow: true,
                        showOnFocus: true,
                        showCheckmark: true,
                        to: false,
                        virtualScroll: false,
                        style: '--v-target-width:200px;--v-target-height:24px',
                        loading: option.loading.value,
                        placeholder: option.search ? 'Type to Search' : 'Please Select',
                        onSearch: option.search,
                        filter: option.filter,
                        onUpdateValue: onUpdate,
                    })
            ),
        render: option.render,
        colSpan: option.colSpan,
    });
    watch(option.value, (value) => {
        if (!value || isEmpty(value)) {
            column.filterOptionValue = null;
        } else {
            column.filterOptionValue = value?.length;
        }
    });
    return column;
}

export const createDateFilterColumn = (option: {
    title: string,
    key: string,
    formattedValue: Ref<[string, string] | undefined>,
    sorter: boolean,
    width?: number | string,
    render?: (rowData: any, rowIndex: number) => VNodeChild,
    colSpan?: (rowData: object, rowIndex: number) => number,
}, onUpdate: (value: [string, string]) => void) => {
    const column: DataTableBaseColumn = reactive<DataTableBaseColumn>({
        title: option.title,
        key: option.key,
        sorter: option.sorter,
        width: option.width,
        filter: true,
        filterOptionValue: null,
        renderFilterMenu:
            () => h(FormItem,
                {
                    style: 'width:250px',
                    showFeedback: false,
                    showLabel: false
                },
                () => h(InputDateRange,
                    {
                        clearable: true,
                        formattedValue: option.formattedValue.value,
                        'onUpdate:formattedValue': (value: [string, string]) => {
                            if (Array.isArray(value) && value.length === 2) {
                              // for date range, move the start datetime to start of the day
                              // and end datetime to end of day in user's local timezone
                              // convert it back to utc
                              let [start, end] = value;

                              start = parseDate(start, 'utc').setZone(zone).startOf('day').setZone('utc').toISO() as string;
                              end = parseDate(end, 'utc').setZone(zone).endOf('day').setZone('utc').toISO() as string;

                              value = [start, end];
                            }
                            onUpdate(value);
                        }
                    })
            ),
        render: option.render,
        colSpan: option.colSpan,
    }) as DataTableBaseColumn;
    watch(option.formattedValue, (value) => {
        if (!value || isEmpty(value)) {
            column.filterOptionValue = null;
        } else {
            column.filterOptionValue = value?.length;
        }
    });
    return column;
}
