import {computed, h, toRefs, useAttrs, defineComponent} from 'vue';

const svg = (attrs, children) => h('svg', attrs, children);
const animate = (attrs) => h('animate', attrs);
const animateTransform = (attrs) => h('animateTransform', attrs);
const g = (attrs, children) => h('g', attrs, children);
const path = (attrs, children) => h('path', attrs, children);
const circle = (attrs, children) => h('circle', attrs, children);
const rect = (attrs, children) => h('rect', attrs, children);

export const LoginIcon = {
  name: 'LoginIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({...attrs, viewBox: '0 0 24 24'}, [
      path({
        'd': 'M12 3c-4.625 0-8.442 3.507-8.941 8.001H10v-3l5 4l-5 4v-3H3.06C3.56 17.494 7.376 21 12 21c4.963 0 9-4.037 9-9s-4.037-9-9-9z',
        'fill': 'currentColor',
      })]);
  },
};


export const LockOpenIcon = {
  name: 'LockOpenIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 24 24' }, [
      path({
        "fill": "currentColor",
        "d": "M6 8h9V6q0-1.25-.875-2.125T12 3t-2.125.875T9 6H7q0-2.075 1.463-3.537T12 1t3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.587 1.413T18 22H6q-.825 0-1.412-.587T4 20V10q0-.825.588-1.412T6 8m6 9q.825 0 1.413-.587T14 15t-.587-1.412T12 13t-1.412.588T10 15t.588 1.413T12 17"
      })
    ]);
  },
};

export const CopyIcon = {
  name: 'CopyIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 24 24' }, [path({
      "fill": "currentColor",
      "d": "M3 18.5v-2h2v2zM3 15v-2h2v2zm0-3.5v-2h2v2zM6.5 22v-2h2v2zm.5-4V2h13v16zm3 4v-2h2v2zm-7 0v-2h2v2zm10.5 0v-2h2v2zM3 8V6h2v2z"
    })]);
  },
};

export const LocationIcon = {
  name: 'LocationIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 20 20' }, [path({
      "fill": "currentColor",
      "d": "M10 2C6.69 2 4 4.69 4 8c0 2.02 1.17 3.71 2.53 4.89c.43.37 1.18.96 1.85 1.83c.74.97 1.41 2.01 1.62 2.71c.21-.7.88-1.74 1.62-2.71c.67-.87 1.42-1.46 1.85-1.83C14.83 11.71 16 10.02 16 8c0-3.31-2.69-6-6-6m0 2.56a3.44 3.44 0 1 1 0 6.88a3.44 3.44 0 0 1 0-6.88"
    })]);
  },
};

export const CheckFilledIcon = {
  name: 'CheckFilledIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 24 24' }, [path({
      "fill": "currentColor",
      "d": "m10.6 16.6l7.05-7.05l-1.4-1.4l-5.65 5.65l-2.85-2.85l-1.4 1.4l4.25 4.25ZM12 22q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Z"
    })]);
  },
};

export const ApplicationProcessIcon = {
  name: 'ApplicationProcessIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 24 24' }, [path({
      "fill": "currentColor",
      "d": "M17 11h3a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-3a2 2 0 0 0-2 2v1H9.01V5a2 2 0 0 0-2-2H4c-1.1 0-2 .9-2 2v4a2 2 0 0 0 2 2h3a2 2 0 0 0 2-2V8h2v7.01c0 1.65 1.34 2.99 2.99 2.99H15v1a2 2 0 0 0 2 2h3a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2h-3a2 2 0 0 0-2 2v1h-1.01c-.54 0-.99-.45-.99-.99V8h2v1c0 1.1.9 2 2 2z"
    })]);
  },
};


export const CloseIcon = {
  name: 'CloseIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 24 24' }, [path({
      "fill": "currentColor",
      "d": "m12 10.586l4.95-4.95l1.415 1.415l-4.95 4.95l4.95 4.95l-1.415 1.414l-4.95-4.95l-4.95 4.95l-1.413-1.415l4.95-4.95l-4.95-4.95L7.05 5.638l4.95 4.95Z"
    })]);
  },
};

export const EmailIcon = {
  name: 'EmailIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 48 48' }, [path({
      "fill": "currentColor",
      "d": "M4.02 13.747A6.25 6.25 0 0 1 10.25 8h27.5a6.25 6.25 0 0 1 6.236 5.828L24.002 24.35zM4 16.567V33.75A6.25 6.25 0 0 0 10.25 40h27.5A6.25 6.25 0 0 0 44 33.75V16.646L24.582 26.87a1.25 1.25 0 0 1-1.168-.002z"
    })]);
  },
};

export const PencilIcon = {
  name: 'PencilIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 16 16' }, [path({
      "fill": "currentColor",
      "d": "M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5L13.5 4.793L14.793 3.5L12.5 1.207zm1.586 3L10.5 3.207L4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175l-.106.106l-1.528 3.821l3.821-1.528l.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"
    })]);
  },
};

export const EyeFillIcon = {
  name: 'EyeFillIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 24 24' }, [path({
      "fill": "currentColor",
      "d": "M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5M12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5s5 2.24 5 5s-2.24 5-5 5m0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3s3-1.34 3-3s-1.34-3-3-3"
    })]);
  },
};

export const ClearFilterIcon = {
  name: 'ClearFilterIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 24 24' }, [path({
      "fill": "currentColor",
      "d": "M10.83 8H21V6H8.83zm5 5H18v-2h-4.17zM14 16.83V18h-4v-2h3.17l-3-3H6v-2h2.17l-3-3H3V6h.17L1.39 4.22L2.8 2.81l18.38 18.38l-1.41 1.41z"
    })]);
  },
};

export const DollarConfigIcon = {
  name: 'DollarConfigIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 24 24' }, [g({
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2"
    }, [
      path({
        "d": "M13.038 20.666c-.902.665-2.393.337-2.713-.983a1.724 1.724 0 0 0-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 0 0-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 0 0 1.066-2.573c-.94-1.543.826-3.31 2.37-2.37c1 .608 2.296.07 2.572-1.065c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 0 0 .402 2.248"
      }),path({
        "d": "M15 12a3 3 0 1 0-1.724 2.716M21 15h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3H17m2 0v1m0-8v1"
      })
    ])]);
  },
};

export const SendIcon = {
  name: 'SendIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 24 24' }, [path({
      "fill": "currentColor",
      "d": "M1.946 9.315c-.522-.174-.526-.455.01-.634L21.044 2.32c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.678.045L12 14l6-8l-8 6z"
    })]);
  },
};

export const InfoIcon = {
  name: 'ContainerIcon',
  setup() {
    const attrs = useAttrs();

    return () => h(
        'svg',
        {
          ...attrs,
          viewBox: '0 0 16 16',
        },
        [
          h('path', {
            fill: 'currentColor',
            d: 'm8.93 6.588l-2.29.287l-.082.38l.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319c.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246c-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0a1 1 0 0 1 2 0z',
          }),
        ],
    );
  },
};

export const AddIconFill = {
  name: 'ContainerIcon',
  setup() {
    const attrs = useAttrs();

    return () => h(
        'svg',
        {
          ...attrs,
          viewBox: '0 0 32 32',
        },
        [
          h('path', {
            fill: 'currentColor',
            d: 'M16 2A14.172 14.172 0 0 0 2 16a14.172 14.172 0 0 0 14 14a14.172 14.172 0 0 0 14-14A14.172 14.172 0 0 0 16 2zm8 15h-7v7h-2v-7H8v-2h7V8h2v7h7z',
          }),
        ],
    );
  },
};

export const SaveIcon = {
  name: 'SaveIcon',
  setup() {
    const attrs = useAttrs();

    return () => h(
        'svg',
        {
          ...attrs,
          viewBox: '0 0 32 32',
        },
        [
          h('path', {
            fill: 'currentColor',
            d: 'M27.71 9.29l-5-5A1 1 0 0 0 22 4H6a2 2 0 0 0-2 2v20a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2V10a1 1 0 0 0-.29-.71zM12 6h8v4h-8zm8 20h-8v-8h8zm2 0v-8a2 2 0 0 0-2-2h-8a2 2 0 0 0-2 2v8H6V6h4v4a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V6.41l4 4V26z',
          }),
        ],
    );
  },
};

export const EditIcon = {
  name: 'EditIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 24 24' }, [path({
      "fill": "currentColor",
      "d": "M4 21q-.425 0-.713-.288T3 20v-2.425q0-.4.15-.763t.425-.637L16.2 3.575q.3-.275.663-.425t.762-.15q.4 0 .775.15t.65.45L20.425 5q.3.275.437.65T21 6.4q0 .4-.138.763t-.437.662l-12.6 12.6q-.275.275-.638.425t-.762.15H4ZM17.6 7.8L19 6.4L17.6 5l-1.4 1.4l1.4 1.4Z"
    })]);
  },
};

export const ForwardSlashIcon = {
  name: 'ForwardSlashIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 24 24' }, [path({
      "fill": "currentColor",
      "d": "m7 21l7.9-18H17L9.1 21H7Z"
    })]);
  },
};

/**
 *
 * @type {DefineComponent<{}, function(): VNode<RendererNode, RendererElement, {[p: string]: any}>, {}, {}, {}, ComponentOptionsMixin, ComponentOptionsMixin, {}, string, PublicProps, ResolveProps<{}, {}>, ExtractDefaultPropTypes<{}>, {}>}
 */
export const DashboardIcon = defineComponent({
  name: 'DashboardIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 32 32' }, [path({
      "fill": "currentColor",
      "d": "M24 21h2v5h-2zm-4-5h2v10h-2zm-9 10a5.006 5.006 0 0 1-5-5h2a3 3 0 1 0 3-3v-2a5 5 0 0 1 0 10z"
    }),path({
      "fill": "currentColor",
      "d": "M28 2H4a2.002 2.002 0 0 0-2 2v24a2.002 2.002 0 0 0 2 2h24a2.003 2.003 0 0 0 2-2V4a2.002 2.002 0 0 0-2-2Zm0 9H14V4h14ZM12 4v7H4V4ZM4 28V13h24l.002 15Z"
    })]);
  },
});


export const EyeIcon = {
  name: 'EyeIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 24 24' }, [path({
      "fill": "currentColor",
      "d": "M12 6.5a9.77 9.77 0 0 1 8.82 5.5c-1.65 3.37-5.02 5.5-8.82 5.5S4.83 15.37 3.18 12A9.77 9.77 0 0 1 12 6.5m0-2C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5m0 5a2.5 2.5 0 0 1 0 5a2.5 2.5 0 0 1 0-5m0-2c-2.48 0-4.5 2.02-4.5 4.5s2.02 4.5 4.5 4.5s4.5-2.02 4.5-4.5s-2.02-4.5-4.5-4.5"
    })]);
  },
};

// export const TrashIcon = {
//   name: 'TrashIcon',
//   setup() {
//     const attrs = useAttrs();
//
//     return () => svg({ ...attrs, viewBox: '0 0 256 256' }, [path({
//       "fill": "currentColor",
//       "d": "M216 48h-36V36a28 28 0 0 0-28-28h-48a28 28 0 0 0-28 28v12H40a12 12 0 0 0 0 24h4v136a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20V72h4a12 12 0 0 0 0-24M100 36a4 4 0 0 1 4-4h48a4 4 0 0 1 4 4v12h-56Zm88 168H68V72h120Zm-72-100v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0m48 0v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0"
//     })]);
//   },
// };


export const TrashIcon = {
  name: 'TrashIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 32 32' }, [path({
      "fill": "currentColor",
      "d": "M12 12h2v12h-2zm6 0h2v12h-2z"
    }),path({
      "fill": "currentColor",
      "d": "M4 6v2h2v20a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8h2V6zm4 22V8h16v20zm4-26h8v2h-8z"
    })]);
  },
};

export const DisconnectIcon = {
  name: 'DisconnectIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 16 16' }, [path({
      "fill": "currentColor",
      "d": "M15.354.646a.5.5 0 0 0-.707 0l-2.07 2.07c-1.37-.999-3.37-.843-4.64.428l-.13.131a1.25 1.25 0 0 0 0 1.768l3.181 3.182c.243.244.563.366.884.366s.641-.122.884-.366l.22-.22a3.47 3.47 0 0 0 1.025-2.529a3.46 3.46 0 0 0-.71-2.059l2.063-2.063a.5.5 0 0 0 0-.708m-7.208 8.5l-.88.88l-1.293-1.293l.88-.88a.5.5 0 0 0-.707-.707l-.88.88l-.253-.253a1.253 1.253 0 0 0-1.768.001l-.22.22A3.47 3.47 0 0 0 2 10.523c.012.756.26 1.47.71 2.059L.647 14.645a.5.5 0 0 0 .708.707l2.07-2.07a3.45 3.45 0 0 0 2.036.652c.938 0 1.888-.363 2.604-1.08l.131-.131a1.25 1.25 0 0 0 0-1.768l-.222-.222l.88-.88a.5.5 0 0 0-.707-.707"
    })]);
  },
};

export const JournalIcon = {
  name: 'JournalIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 16 16' }, [g({
      "fill": "currentColor"
    }, [
      path({
        "d": "M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5"
      }),path({
        "d": "M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2"
      }),path({
        "d": "M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z"
      })
    ])]);
  },
};




export const ArchiveIcon = {
  name: 'ArchiveIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 24 24' }, [path({
      "fill": "currentColor",
      "d": "M2 5c0-.943 0-1.414.293-1.707C2.586 3 3.057 3 4 3h16c.943 0 1.414 0 1.707.293C22 3.586 22 4.057 22 5c0 .943 0 1.414-.293 1.707C21.414 7 20.943 7 20 7H4c-.943 0-1.414 0-1.707-.293C2 6.414 2 5.943 2 5m18.069 3.5c.141 0 .286 0 .431-.002V13c0 3.771 0 5.657-1.172 6.828C18.157 21 16.271 21 12.5 21h-1c-3.771 0-5.657 0-6.828-1.172C3.5 18.657 3.5 16.771 3.5 13V8.498c.145.002.29.002.431.002h7.319v6.545l-1.693-1.88a.75.75 0 1 0-1.114 1.003l3 3.334a.75.75 0 0 0 1.114 0l3-3.334a.75.75 0 0 0-1.114-1.003l-1.693 1.88V8.5z"
    })]);
  },
};

export const RestoreIcon = {
  name: 'RestoreIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 24 24' }, [path({
      "fill": "currentColor",
      "d": "M2 5c0-.943 0-1.414.293-1.707C2.586 3 3.057 3 4 3h16c.943 0 1.414 0 1.707.293C22 3.586 22 4.057 22 5c0 .943 0 1.414-.293 1.707C21.414 7 20.943 7 20 7H4c-.943 0-1.414 0-1.707-.293C2 6.414 2 5.943 2 5m18.069 3.5c.141 0 .286 0 .431-.002V13c0 3.771 0 5.657-1.172 6.828C18.183 20.974 16.355 21 12.75 21v-7.046l1.693 1.88a.75.75 0 1 0 1.114-1.002l-3-3.334a.75.75 0 0 0-1.114 0l-3 3.334a.75.75 0 1 0 1.114 1.003l1.693-1.88V21c-3.605 0-5.433-.026-6.578-1.172C3.5 18.657 3.5 16.771 3.5 13V8.498c.145.002.29.002.431.002H20.07"
    })]);
  },
};



export const SitemapIcon = {
  name: 'SitemapIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 24 24' }, [path({
      "fill": "currentColor",
      "d": "M9 2v6h2v3H5c-1.11 0-2 .89-2 2v3H1v6h6v-6H5v-3h6v3H9v6h6v-6h-2v-3h6v3h-2v6h6v-6h-2v-3c0-1.11-.89-2-2-2h-6V8h2V2z"
    })]);
  },
};



export const ShapeIcon = {
  name: 'ShapeIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 512 512' }, [path({
      "fill": "currentColor",
      "d": "M363.27 336H4.73L184 16Z"
    }),path({
      "fill": "currentColor",
      "d": "M336 160a160.54 160.54 0 0 0-32.55 3.36l87.75 157L417.81 368H183.36C203.8 432.85 264.49 480 336 480c88.22 0 160-71.78 160-160s-71.78-160-160-160"
    })]);
  },
};





export const ShapesIcon = {
  name: 'ShapesIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 24 24' }, [path({
      "fill": "currentColor",
      "d": "M3.7 11q-.575 0-.862-.488t-.013-.987l3.3-5.95q.275-.5.875-.5t.875.5l3.3 5.95q.275.5-.012.988T10.3 11zM7 21q-1.65 0-2.825-1.175T3 17q0-1.65 1.175-2.825T7 13q1.65 0 2.825 1.175T11 17q0 1.65-1.175 2.825T7 21m7 0q-.425 0-.712-.288T13 20v-6q0-.425.288-.712T14 13h6q.425 0 .713.288T21 14v6q0 .425-.288.713T20 21zm1.6-14.5l-1.9-1.9q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275L17 5.1l1.9-1.9q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7l-1.9 1.9l1.9 1.9q.275.275.275.7t-.275.7q-.275.275-.7.275t-.7-.275L17 7.9l-1.9 1.9q-.275.275-.7.275t-.7-.275q-.275-.275-.275-.7t.275-.7z"
    })]);
  },
};



export const ListIcon = {
  name: 'ListIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 24 24' }, [path({
      "fill": "currentColor",
      "d": "M3 4h4v4H3V4m6 1v2h12V5H9m-6 5h4v4H3v-4m6 1v2h12v-2H9m-6 5h4v4H3v-4m6 1v2h12v-2H9"
    })]);
  },
};

export const PercentIcon = {
  name: 'PercentIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 24 24' }, [path({
      "fill": "currentColor",
      "d": "m18.5 3.5l2 2l-15 15l-2-2l15-15M7 4c1.66 0 3 1.34 3 3s-1.34 3-3 3s-3-1.34-3-3s1.34-3 3-3m10 10c1.66 0 3 1.34 3 3s-1.34 3-3 3s-3-1.34-3-3s1.34-3 3-3M7 6c-.55 0-1 .45-1 1s.45 1 1 1s1-.45 1-1s-.45-1-1-1m10 10c-.55 0-1 .45-1 1s.45 1 1 1s1-.45 1-1s-.45-1-1-1Z"
    })]);
  },
};

export const RollbackIcon = {
  name: 'RollbackIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({...attrs, viewBox: '0 0 1024 1024'}, [
      path({
        fill: 'currentColor',
        d: 'M793 242H366v-74c0-6.7-7.7-10.4-12.9-6.3l-142 112a8 8 0 0 0 0 12.6l142 112c5.2 4.1 12.9.4 12.9-6.3v-74h415v470H175c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h618c35.3 0 64-28.7 64-64V306c0-35.3-28.7-64-64-64z',
      }),
    ]);
  },
};
export const RefreshIcon = {
  name: 'RefreshIcon',
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 12 12' }, [path({
      "fill": "currentColor",
      "d": "M8.966 6.453c.038-.248.238-.453.489-.453c.3 0 .549.246.508.544A4 4 0 1 1 9 3.354V2.5a.5.5 0 0 1 1 0v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h.736a3 3 0 1 0 .73 2.453"
    })]);
  },
};

export const ListItemIcon = {
  name: 'ListItemIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 24 24' }, [g({
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2"
    }, [
      path({
        "d": "m16 12.5l-5-3l5-3l5 3V15l-5 3z"
      }),path({
        "d": "M11 9.5V15l5 3m0-5.455l5-3.03M7 9H2m5 3H4m3 3H6"
      })
    ])]);
  },
};



export const LayoutIcon = {
  name: 'LayoutIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 14 14' }, [g({
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, [
      rect({
        "width": "5",
        "height": "7",
        "x": "8.5",
        "y": "6.5",
        "rx": ".5"
      }),rect({
        "width": "5",
        "height": "3.01",
        "x": "8.5",
        "y": ".5",
        "rx": ".5"
      }),rect({
        "width": "5",
        "height": "7",
        "x": ".5",
        "y": ".5",
        "rx": ".5"
      }),rect({
        "width": "5",
        "height": "3.01",
        "x": ".5",
        "y": "10.49",
        "rx": ".5"
      })
    ])]);
  },
};

export const InfoCircleFilledIcon = {
  name: 'InfoCircleFilledIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({viewBox: '0 0 32 32', ...attrs}, [
      path({
        fill: 'none',
        d: 'M16 8a1.5 1.5 0 1 1-1.5 1.5A1.5 1.5 0 0 1 16 8Zm4 13.875h-2.875v-8H13v2.25h1.875v5.75H12v2.25h8Z',
      }),
      path({
        fill: 'currentColor',
        d: 'M16 2a14 14 0 1 0 14 14A14 14 0 0 0 16 2Zm0 6a1.5 1.5 0 1 1-1.5 1.5A1.5 1.5 0 0 1 16 8Zm4 16.125h-8v-2.25h2.875v-5.75H13v-2.25h4.125v8H20Z',
      }),
    ]);
  },
};

export const WarningIcon = {
  name: 'WarningIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({viewBox: '0 0 32 32', ...attrs}, [
      path({
        fill: 'currentColor',
        d: 'M16 2C8.3 2 2 8.3 2 16s6.3 14 14 14s14-6.3 14-14S23.7 2 16 2zm-1.1 6h2.2v11h-2.2V8zM16 25c-.8 0-1.5-.7-1.5-1.5S15.2 22 16 22s1.5.7 1.5 1.5S16.8 25 16 25z',
      }),
    ]);
  },
};

export const ImportIcon = {
  name: 'ImportIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({viewBox: '0 0 32 32', ...attrs}, [
      path({
        fill: 'currentColor',
        d: 'M26 24v4H6v-4H4v4a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2v-4zm0-10l-1.41-1.41L17 20.17V2h-2v18.17l-7.59-7.58L6 14l10 10l10-10z',
      }),
    ]);
  },
};

export const LaunchIcon = {
  name: 'LaunchIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({viewBox: '0 0 32 32', ...attrs}, [
      path({
        fill: 'currentColor',
        d: 'M26 28H6a2.003 2.003 0 0 1-2-2V6a2.003 2.003 0 0 1 2-2h10v2H6v20h20V16h2v10a2.003 2.003 0 0 1-2 2Z',
      }),
      path({fill: 'currentColor', d: 'M20 2v2h6.586L18 12.586L19.414 14L28 5.414V12h2V2H20z'}),
    ]);
  },
};

export const RocketIcon = {
  name: 'RocketIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({viewBox: '0 0 24 24', ...attrs}, [
      path({
        fill: 'currentColor',
        d: 'm2.45 10.575l4.2-4.2q.35-.35.825-.5t.975-.05l1.3.275Q8.4 7.7 7.625 9t-1.5 3.15L2.45 10.575Zm5.125 2.275q.575-1.8 1.563-3.4t2.387-3q2.2-2.2 5.025-3.288t5.275-.662q.425 2.45-.65 5.275T17.9 12.8q-1.375 1.375-3 2.388t-3.425 1.587l-3.9-3.925Zm6.9-3q.575.575 1.413.575T17.3 9.85q.575-.575.575-1.412T17.3 7.025q-.575-.575-1.413-.575t-1.412.575Q13.9 7.6 13.9 8.438t.575 1.412Zm-.7 12.025l-1.6-3.675q1.85-.725 3.163-1.5t2.912-2.125l.25 1.3q.1.5-.05.988t-.5.837l-4.175 4.175ZM4.05 16.05q.875-.875 2.125-.888t2.125.863q.875.875.875 2.125T8.3 20.275q-.625.625-2.087 1.075t-4.038.8q.35-2.575.8-4.025T4.05 16.05Z',
      }),
    ]);
  },
};

export const SyncIcon = {
  name: 'SyncIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 24 24' }, [path({
      "fill": "currentColor",
      "d": "M21 10.12h-6.78l2.74-2.82c-2.73-2.7-7.15-2.8-9.88-.1a6.887 6.887 0 0 0 0 9.8c2.73 2.7 7.15 2.7 9.88 0c1.36-1.35 2.04-2.92 2.04-4.9h2c0 1.98-.88 4.55-2.64 6.29c-3.51 3.48-9.21 3.48-12.72 0c-3.5-3.47-3.53-9.11-.02-12.58a8.987 8.987 0 0 1 12.65 0L21 3v7.12M12.5 8v4.25l3.5 2.08l-.72 1.21L11 13V8h1.5Z"
    })]);
  },
};

export const TownHallIcon = {
  name: 'TownHallIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 11 11' }, [path({
      "d": "M5.5 0L1 2v1h9V2L5.5 0zM2 4v4L1 9v1h9V9L9 8V4H2zm1 1h1v3H3V5zm2 0h1v3H5V5zm2 0h1v3H7V5z",
      "fill": "currentColor"
    })]);
  },
};


export const ExternalLinkIcon = {
  name: 'ExternalLinkIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 24 24' }, [path({
      "fill": "currentColor",
      "d": "m13 3l3.293 3.293l-7 7l1.414 1.414l7-7L21 11V3z"
    }),path({
      "fill": "currentColor",
      "d": "M19 19H5V5h7l-2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2v-5l-2-2z"
    })]);
  },
};



export const ForbidIcon = {
  name: 'ForbidIcon',
  setup() {
    const attrs = useAttrs();

    return () => h(
        'svg',
        {
          ...attrs,
          viewBox: '0 0 24 24',
        },
        h('g', {fill: '#fdba74'}, [
          h('path', {
            opacity: 0.5,
            d: 'M4.929 4.929c-3.905 3.905-3.905 10.237 0 14.142c3.905 3.905 10.237 3.905 14.142 0c3.905-3.905 3.905-10.237 0-14.142c-3.905-3.905-10.237-3.905-14.142 0Z',
          }),
          h('path',
              {d: 'M18.521 4.418L4.418 18.521a10.127 10.127 0 0 0 1.06 1.061L19.583 5.479a10.105 10.105 0 0 0-1.06-1.06Z'}),
        ]),
    );
  },
};

export const SearchIcon = {
  name: 'SpaIcon',
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
  setup(props) {
    const attrs = useAttrs();

    return () => h(
        'svg',
        {
          ...attrs,
          viewBox: '0 0 24 24',
        },
        [
          h('path', {
            fill: props.fill,
            d: 'm18.031 16.617l4.283 4.282l-1.415 1.415l-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9s9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617Zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.867-3.133-7-7-7s-7 3.133-7 7s3.133 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15Z',
          }),
        ],
    );
  },
};

export const LoadingIcon = {
  name: 'LoadingIcon',
  props: {
    stroke: {
      type: String,
      default: 'currentColor',
    },
  },
  setup(props) {
    const attrs = useAttrs();

    return () => h(
        'svg',
        {
          ...attrs,
          viewBox: '0 0 24 24',
        },
        h('g', {fill: 'none', stroke: props.stroke, strokeLinecap: 'round', strokeWidth: 2}, [
          h(
              'path',
              {
                strokeDasharray: 60,
                strokeDashoffset: 60,
                strokeOpacity: 0.3,
                d: 'M12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3Z',
              },
              [
                h('animate', {
                  fill: 'freeze',
                  attributeName: 'stroke-dashoffset',
                  dur: '1.3s',
                  values: '60;0',
                }),
              ],
          ),

          h(
              'path',
              {
                strokeDasharray: 15,
                strokeDashoffset: 15,
                d: 'M12 3C16.9706 3 21 7.02944 21 12',
              },
              [
                h('animate', {
                  fill: 'freeze',
                  attributeName: 'stroke-dashoffset',
                  dur: '0.3s',
                  values: '15;0',
                }),
                h('animateTransform', {
                  attributeName: 'transform',
                  dur: '1.3s',
                  repeatCount: 'indefinite',
                  type: 'rotate',
                  values: '0 12 12;360 12 12',
                }),
              ],
          ),
        ]),
    );
  },
};

export const RotateClockwiseArrowIcon = {
  name: 'RotateClockwiseArrowIcon',
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
  setup(props) {
    const attrs = useAttrs();

    return () => h(
        'svg',
        {
          ...attrs,
          viewBox: '0 0 48 48',
        },
        [
          h('path', {
            fill: props.fill,
            d: 'M24 39c8.284 0 15-6.716 15-15c0-.483-.023-.96-.067-1.43c-.078-.817.534-1.57 1.354-1.57c.845 0 1.576.61 1.647 1.452c.044.51.066 1.026.066 1.548c0 9.941-8.059 18-18 18S6 33.941 6 24S14.059 6 24 6c4.732 0 9.037 1.826 12.25 4.811V8.25a1.5 1.5 0 0 1 3 0v6.5a1.5 1.5 0 0 1-1.5 1.5h-6.5a1.5 1.5 0 0 1 0-3h3.211A14.951 14.951 0 0 0 24 9C15.716 9 9 15.716 9 24c0 8.284 6.716 15 15 15Z',
          }),
        ],
    );
  },
};

// export const RollbackIcon = {
//   name: 'RollbackIcon',
//   setup() {
//     const attrs = useAttrs();
//
//     return () => h(
//         'svg',
//         {
//           ...attrs,
//           viewBox: '0 0 1024 1024',
//         },
//         [
//           h('path', {
//             fill: 'currentColor',
//             d: 'M793 242H366v-74c0-6.7-7.7-10.4-12.9-6.3l-142 112a8 8 0 0 0 0 12.6l142 112c5.2 4.1 12.9.4 12.9-6.3v-74h415v470H175c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h618c35.3 0 64-28.7 64-64V306c0-35.3-28.7-64-64-64z',
//           }),
//         ],
//     );
//   },
// };

export const TimelineIcon = {
  name: 'TimelineIcon',
  setup() {
    const attrs = useAttrs();

    return () => h(
        'svg',
        {
          ...attrs,
          viewBox: '0 0 24 24',
        },
        [
          h('path', {
            fill: 'currentColor',
            d: 'M5 12c0 1.11-.89 2-2 2a2 2 0 1 1 2-2M4 2v6H2V2h2M2 22v-6h2v6H2M24 6v12c0 1.11-.89 2-2 2H10a2 2 0 0 1-2-2v-4l-2-2l2-2V6a2 2 0 0 1 2-2h12c1.11 0 2 .89 2 2m-2 0H10v4.83L8.83 12L10 13.17V18h12V6M12 9h8v2h-8V9m0 4h6v2h-6v-2Z',
          }),
        ],
    );
  },
};

export const AddIcon = {
  name: 'AddIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 20 20' }, [path({
      "fill": "currentColor",
      "d": "M17 9v2h-6v6H9v-6H3V9h6V3h2v6z"
    })]);
  },
};

export const LogIcon = {
  name: 'LogIcon',
  setup() {
    const attrs = useAttrs();

    return () => h(
        'svg',
        {
          ...attrs,
          viewBox: '0 0 48 48',
        },
        [
          h('g', {
            fill: 'none',
            stroke: 'currentColor',
            'stroke-linecap': 'round',
            'stroke-width': 3,
          }, [
            h('path', {d: 'M13 10h28v34H13z'}),
            h('path', {'stroke-linecap': 'round', d: 'M35 10V4H8a1 1 0 0 0-1 1v33h6m8-16h12m-12 8h12'}),
          ]),
        ],
    );
  },
};

export const CommentIcon = {
  name: 'CommentIcon',
  setup() {
    const attrs = useAttrs();

    return () => h(
        'svg',
        {
          ...attrs,
          viewBox: '0 0 48 48',
        },
        [
          h('g', {
            fill: 'none',
            stroke: 'currentColor',
            'stroke-linecap': 'round',
            'stroke-width': 3,
          }, [
            h('path', {
              'stroke-linejoin': 'round',
              d: 'M33 38H22v-8h14v-8h8v16h-5l-3 3l-3-3Z',
            }),
            h('path', {
              'stroke-linejoin': 'round',
              d: 'M4 6h32v24H17l-4 4l-4-4H4V6Z',
            }),
            h('path', {d: 'M12 22h6m-6-8h12'}),
          ]),
        ],
    );
  },
};

export const HistoryIcon = {
  name: 'HistoryIcon',
  setup() {
    const attrs = useAttrs();

    return () => h(
        'svg',
        {
          ...attrs,
          viewBox: '0 0 20 20',
        },
        [
          h('g', {fill: 'none'}, [
            h('path', {
              fill: 'currentColor',
              d: 'M3.5 1.996l.09.008a.5.5 0 0 1 .402.402l.008.09v2.212a8 8 0 1 1-1.865 6.75a10.314 10.314 0 0 1-.13-1.98a.5.5 0 0 1 .998.042a9.36 9.36 0 0 0 .114 1.755a7 7 0 1 0 1.141-5.28l3.242.001l.09.008a.5.5 0 0 1 0 .984l-.09.008h-4l-.09-.008a.5.5 0 0 1-.402-.402L3 6.496v-4l.008-.09a.5.5 0 0 1 .402-.402l.09-.008zm6 4l.09.008a.5.5 0 0 1 .402.402l.008.09V10.5h2l.09.008a.5.5 0 0 1 0 .984L12 11.5H9.5l-.09-.008a.5.5 0 0 1-.402-.402L9 11V6.496l.008-.09a.5.5 0 0 1 .402-.402l.09-.008z',
            }),
          ]),
        ],
    );
  },
};


export const CheckedCircleFilledIcon = {
  name: 'CheckedCircleFilledIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 24 24' }, [path({
      "fill": "currentColor",
      "d": "m10.6 16.6l7.05-7.05l-1.4-1.4l-5.65 5.65l-2.85-2.85l-1.4 1.4l4.25 4.25ZM12 22q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Z"
    })]);
  },
};

export const CloseCircleFilledIcon = {
  name: 'CloseCircleFilledIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 24 24' }, [path({
      "fill": "currentColor",
      "d": "m8.4 17l3.6-3.6l3.6 3.6l1.4-1.4l-3.6-3.6L17 8.4L15.6 7L12 10.6L8.4 7L7 8.4l3.6 3.6L7 15.6zm3.6 5q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22"
    })]);
  },
};



export const ExportReportIcon = {
  name: 'ExportReportIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 24 24' }, [path({
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "d": "M12.5 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v7.5M3 10h18M10 3v18m6-2h6m-3-3l3 3l-3 3"
    })]);
  },
};

export const DownloadIcon = {
  name: 'DownloadIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 24 24' }, [path({
      "fill": "currentColor",
      "d": "M16.59 9H15V4c0-.55-.45-1-1-1h-4c-.55 0-1 .45-1 1v5H7.41c-.89 0-1.34 1.08-.71 1.71l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59c.63-.63.19-1.71-.7-1.71M5 19c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H6c-.55 0-1 .45-1 1"
    })]);
  },
};

export const FilesIcon = {
  name: 'FilesIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 256 256' }, [path({
      "fill": "currentColor",
      "d": "m213.66 66.34l-40-40A8 8 0 0 0 168 24H88a16 16 0 0 0-16 16v16H56a16 16 0 0 0-16 16v144a16 16 0 0 0 16 16h112a16 16 0 0 0 16-16v-16h16a16 16 0 0 0 16-16V72a8 8 0 0 0-2.34-5.66M136 192H88a8 8 0 0 1 0-16h48a8 8 0 0 1 0 16m0-32H88a8 8 0 0 1 0-16h48a8 8 0 0 1 0 16m64 24h-16v-80a8 8 0 0 0-2.34-5.66l-40-40A8 8 0 0 0 136 56H88V40h76.69L200 75.31Z"
    })]);
  },
};

export const ExcelIcon = {
  name: 'ExcelIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 512 512' }, [path({
      "fill": "currentColor",
      "d": "M453.547 273.449H372.12v-40.714h81.427zm0 23.264H372.12v40.714h81.427zm0-191.934H372.12v40.713h81.427zm0 63.978H372.12v40.713h81.427zm0 191.934H372.12v40.714h81.427zm56.242 80.264c-2.326 12.098-16.867 12.388-26.58 12.796H302.326v52.345h-36.119L0 459.566V52.492L267.778 5.904h34.548v46.355h174.66c9.83.407 20.648-.291 29.197 5.583c5.991 8.608 5.41 19.543 5.817 29.43l-.233 302.791c-.29 16.925 1.57 34.2-1.978 50.892m-296.51-91.256c-16.052-32.57-32.395-64.909-48.39-97.48c15.82-31.698 31.408-63.512 46.937-95.327c-13.203.64-26.406 1.454-39.55 2.385c-9.83 23.904-21.288 47.169-28.965 71.888c-7.154-23.323-16.634-45.774-25.3-68.515c-12.796.698-25.592 1.454-38.387 2.21c13.493 29.78 27.86 59.15 40.946 89.104c-15.413 29.081-29.837 58.57-44.785 87.825c12.737.523 25.475 1.047 38.212 1.221c9.074-23.148 20.357-45.424 28.267-69.038c7.096 25.359 19.135 48.798 29.023 73.051c14.017.99 27.976 1.862 41.993 2.676M484.26 79.882H302.326v24.897h46.53v40.713h-46.53v23.265h46.53v40.713h-46.53v23.265h46.53v40.714h-46.53v23.264h46.53v40.714h-46.53v23.264h46.53v40.714h-46.53v26.897H484.26z"
    })]);
  },
};

export const AcrobatIcon = {
  name: 'AcrobatIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 512 512' }, [path({
      "fill": "currentColor",
      "d": "M239.01 184.785c-20.468-43.397-28.554-113.315.466-114.501c31.963 2.856 12.484 68.746-.465 114.501m-28.625 139.87c32.753-10.812 65.891-19.729 99.608-25.833c-21.275-21.026-40.114-46.38-57.018-75.171c-12.675 37.215-26.21 70.99-42.59 101.004M76.567 430.778c22.463 18.02 62.65-33.144 92.16-83.083c-54.638 23.06-114.911 58.88-92.16 83.083M512 0v512H0V0zM319.07 297.193c-24.272-23.438-45.214-51.263-63.303-82.851c24.286-75.684 34.517-148.715-16.29-151.506c-43.69 3.139-25.935 85.404-4.19 132.422c-16.76 49.901-35.699 97.958-60.509 141.964c-63.472 24.13-133.456 67.14-102.865 99.142c44.86 31.786 96.254-37.47 132.887-101.935c36.226-12.694 73.691-22.063 111.942-29.324c50.884 48.248 125.822 61.062 138.705 30.022c11.147-40.565-51.62-52.19-136.378-37.934m7.214 6.283c41.448 36.107 96.592 54.22 105.192 22.342c7.551-35.495-61.321-28.92-105.192-22.342"
    })]);
  },
};

export const ChevronLeftIcon = {
  name: 'ChevronLeftIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 16 16' }, [path({
      "fill": "currentColor",
      "fill-rule": "evenodd",
      "d": "M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z"
    })]);
  },
};


export const ChevronRightIcon = {
  name: 'ChevronRightIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 16 16' }, [path({
      "fill": "currentColor",
      "fill-rule": "evenodd",
      "d": "M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8L6.553 2.224a.5.5 0 0 1 .223-.671z"
    })]);
  },
};

export const RuleCancelledIcon = {
  name: 'RuleCancelledIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 32 32' }, [path({
      "fill": "currentColor",
      "d": "M30 24a6 6 0 1 0-6 6a6.007 6.007 0 0 0 6-6zm-2 0a3.952 3.952 0 0 1-.567 2.019l-5.452-5.452A3.953 3.953 0 0 1 24 20a4.005 4.005 0 0 1 4 4zm-8 0a3.952 3.952 0 0 1 .567-2.019l5.452 5.452A3.953 3.953 0 0 1 24 28a4.005 4.005 0 0 1-4-4zM8 16h10v2H8zm0-6h12v2H8z"
    }),path({
      "fill": "currentColor",
      "d": "m14 27.733l-5.234-2.79A8.986 8.986 0 0 1 4 17V4h20v11h2V4a2.002 2.002 0 0 0-2-2H4a2.002 2.002 0 0 0-2 2v13a10.981 10.981 0 0 0 5.824 9.707L14 30Z"
    })]);
  },
};


export const CancelIcon = {
  name: 'CancelIcon',
  setup() {
    const attrs = useAttrs();

    return () => h(
        'svg',
        {
          ...attrs,
          viewBox: '0 0 32 32',
        },
        [
          h('path', {
            fill: 'currentColor',
            d: 'M30 24a6 6 0 1 0-6 6a6.007 6.007 0 0 0 6-6zm-2 0a3.952 3.952 0 0 1-.567 2.019l-5.452-5.452A3.953 3.953 0 0 1 24 20a4.005 4.005 0 0 1 4 4zm-8 0a3.952 3.952 0 0 1 .567-2.019l5.452 5.452A3.953 3.953 0 0 1 24 28a4.005 4.005 0 0 1-4-4z',
          }),
          h('path', {
            fill: 'currentColor',
            d: 'M8 16h10v2H8z',
          }),
          h('path', {
            fill: 'currentColor',
            d: 'M8 10h12v2H8z',
          }),
          h('path', {
            fill: 'currentColor',
            d: 'M14 27.733l-5.234-2.79A8.986 8.986 0 0 1 4 17V4h20v11h2V4a2.002 2.002 0 0 0-2-2H4a2.002 2.002 0 0 0-2 2v13a10.981 10.981 0 0 0 5.824 9.707L14 30z',
          }),
        ],
    );
  },
};

export const AssignUserIcon = {
  name: 'AssignUserIcon',
  setup() {
    const attrs = useAttrs();

    return () => h(
        'svg',
        {
          ...attrs,
          viewBox: '0 0 48 48',
        },
        [
          h('g', {
            fill: 'none',
            stroke: 'currentColor',
            'stroke-linecap': 'round',
            'stroke-linejoin': 'round',
            'stroke-width': 4,
          }, [
            h('circle', {cx: 24, cy: 11, r: 7}),
            h('path', {d: 'M4 41c0-8.837 8.059-16 18-16m5 6h14v10H27zm10 0v-3a3 3 0 1 0-6 0v3'}),
          ]),
        ],
    );
  },
};

export const ConsultantIcon = {
  name: 'ConsultantIcon',
  setup() {
    const attrs = useAttrs();

    return () => h(
        'svg',
        {
          ...attrs,
          viewBox: '0 0 24 24',
        }, [
          h('path', {
            fill: 'currentColor',
            d: 'M11 21v-2h8v-7.1q0-1.45-.55-2.725t-1.5-2.225Q16 6 14.725 5.45T12 4.9q-1.45 0-2.725.55T7.05 6.95q-.95.95-1.5 2.225T5 11.9v5.875H2v-5.55h1l.075-1.275q.225-1.825 1.038-3.3t2.025-2.5Q7.35 4.125 8.863 3.562T12 3q1.65 0 3.163.563t2.712 1.6q1.2 1.037 2.013 2.5t1.037 3.262l.075 1.3h1v5.55h-1V21H11Zm-2-7q-.425 0-.713-.288T8 13q0-.425.288-.713T9 12q.425 0 .713.288T10 13q0 .425-.288.713T9 14Zm6 0q-.425 0-.713-.288T14 13q0-.425.288-.713T15 12q.425 0 .713.288T16 13q0 .425-.288.713T15 14Zm-8.975-1.55q-.1-1.475.413-2.675t1.375-2.038Q8.675 6.9 9.8 6.45T12.05 6q2.275 0 3.938 1.438T18 11.024Q15.65 11 13.75 9.762T10.875 6.55q-.4 2.025-1.688 3.588T6.026 12.45Z',
          }),
        ],
    );
  },
};

export const PhoneIcon = {
  name: 'PhoneIcon',
  setup() {
    const attrs = useAttrs();

    return () => h(
        'svg',
        {
          ...attrs,
          viewBox: '0 0 32 32',
        }, [
          h('path', {
            fill: 'currentColor',
            d: 'm20.33 21.48l2.24-2.24a2.19 2.19 0 0 1 2.34-.48l2.73 1.09a2.18 2.18 0 0 1 1.36 2v5A2.17 2.17 0 0 1 26.72 29C7.59 27.81 3.73 11.61 3 5.41A2.17 2.17 0 0 1 5.17 3H10a2.16 2.16 0 0 1 2 1.36l1.09 2.73a2.16 2.16 0 0 1-.47 2.34l-2.24 2.24s1.29 8.73 9.95 9.81Z',
          }),
        ],
    );
  },
};

export const PersonIcon = {
  name: 'PersonIcon',
  setup() {
    const attrs = useAttrs();

    return () => h(
        'svg',
        {
          ...attrs,
          viewBox: '0 0 24 24',
        }, [
          h('path', {
            fill: 'currentColor',
            d: 'M12 12q-1.65 0-2.825-1.175T8 8q0-1.65 1.175-2.825T12 4q1.65 0 2.825 1.175T16 8q0 1.65-1.175 2.825T12 12Zm-8 8v-2.8q0-.85.438-1.563T5.6 14.55q1.55-.775 3.15-1.163T12 13q1.65 0 3.25.388t3.15 1.162q.725.375 1.163 1.088T20 17.2V20H4Z',
          }),
        ],
    );
  },
};

export const QuestionCircleFilledIcon = {
  name: 'MilestoneIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({...attrs, viewBox: '0 0 32 32'}, [
      path({
        'fill': 'currentColor',
        'd': 'M16 2a14 14 0 1 0 14 14A14 14 0 0 0 16 2Zm0 23a1.5 1.5 0 1 1 1.5-1.5A1.5 1.5 0 0 1 16 25Zm1.142-7.754v2.501h-2.25V15h2.125a2.376 2.376 0 0 0 0-4.753h-1.5a2.378 2.378 0 0 0-2.375 2.375v.638h-2.25v-.638A4.628 4.628 0 0 1 15.517 8h1.5a4.624 4.624 0 0 1 .125 9.246Z',
      }), path({
        'fill': 'none',
        'd': 'M16 25a1.5 1.5 0 1 1 1.5-1.5A1.5 1.5 0 0 1 16 25Zm1.142-7.754v2.501h-2.25V15h2.125a2.376 2.376 0 0 0 0-4.753h-1.5a2.378 2.378 0 0 0-2.375 2.375v.638h-2.25v-.638A4.628 4.628 0 0 1 15.517 8h1.5a4.624 4.624 0 0 1 .125 9.246Z',
      })]);
  },
};

export const StopwatchIcon = {
  name: 'StopwatchIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({...attrs, viewBox: '0 0 15 15'}, [
      path({
        'fill': 'currentColor',
        'd': 'M9 1H6V0h3v1Z',
      }), path({
        'fill': 'currentColor',
        'fill-rule': 'evenodd',
        'd': 'M7.5 2a6.5 6.5 0 1 0 0 13a6.5 6.5 0 0 0 0-13ZM8 8V5H7v4h3V8H8Z',
        'clip-rule': 'evenodd',
      })]);
  },
};

export const MilestoneIcon = {
  name: 'MilestoneIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 16 16' }, [path({
      "fill": "currentColor",
      "d": "M7.75 0a.75.75 0 0 1 .75.75V3h3.634c.414 0 .814.147 1.13.414l2.07 1.75a1.75 1.75 0 0 1 0 2.672l-2.07 1.75a1.75 1.75 0 0 1-1.13.414H8.5v5.25a.75.75 0 0 1-1.5 0V10H2.75A1.75 1.75 0 0 1 1 8.25v-3.5C1 3.784 1.784 3 2.75 3H7V.75A.75.75 0 0 1 7.75 0Zm4.384 8.5a.25.25 0 0 0 .161-.06l2.07-1.75a.248.248 0 0 0 0-.38l-2.07-1.75a.25.25 0 0 0-.161-.06H2.75a.25.25 0 0 0-.25.25v3.5c0 .138.112.25.25.25h9.384Z"
    })]);
  },
};

export const ListCheckedIcon = {
  name: 'ListCheckedIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({...attrs, viewBox: '0 0 24 24'}, [
      path({
        'fill': 'currentColor',
        'd': 'M3 16v-2h8v2H3Zm0-4v-2h12v2H3Zm0-4V6h12v2H3Zm13.35 11l-3.55-3.55l1.4-1.4l2.15 2.1l4.25-4.25l1.4 1.45L16.35 19Z',
      })]);
  },
};

export const DollarCircleIcon = {
  name: 'DollarCircleIcon',
  setup() {
    const attrs = useAttrs();

    return () => svg({ ...attrs, viewBox: '0 0 256 256' }, [path({
      "fill": "currentColor",
      "d": "M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm20 168h-12v8a8 8 0 0 1-16 0v-8h-8a36 36 0 0 1-36-36a8 8 0 0 1 16 0a20 20 0 0 0 20 20h36a20 20 0 0 0 0-40h-32a36 36 0 0 1 0-72h4v-8a8 8 0 0 1 16 0v8h4a36 36 0 0 1 36 36a8 8 0 0 1-16 0a20 20 0 0 0-20-20h-24a20 20 0 0 0 0 40h32a36 36 0 0 1 0 72Z"
    })]);
  },
};
