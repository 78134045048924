import {SelfController} from "@admin/types/generated.ts";
import {useAsyncResponse} from '@core/composable/useAsyncCallback.js';
import {isObject} from "lodash";
import {defineStore} from 'pinia';
import {computed, ref} from 'vue';

export const enableThemeEditor = ref(false);

const useMyProfile = defineStore('MyProfile', () => {
  const request = () => SelfController.profile();
  const {loading, execute, data: user} = useAsyncResponse();
  const activeInhouseTask = computed({
    get: () => {
      return user?.value?.active_inhouse_task;
    }, set: (value) => {
      user.value.active_inhouse_task = value;
    }
  });
  const activeAdhocTask = computed({
    get: () => {
      return user?.value?.active_adhoc_task;
    }, set: (value) => {
      user.value.active_adhoc_task = value;
    }
  });

  const activeApplicationTask = computed({
    get: () => {
      return user?.value?.active_application_task;
    }, set: (value) => {
      user.value.active_application_task = value;
    }
  });

  const load = () => execute(request(), () => {
    if (!isObject(user.value)) {
      user.value = {} as any;
    }
  });
  void load();
  return {
    user,
    loading,
    load,
    enableThemeEditor,
    activeApplicationTask,
    activeAdhocTask,
    activeInhouseTask,
  };
});

export default useMyProfile;
