<template>
  <AppConfig>
    <NDialogProvider>
      <NMessageProvider
        :duration="3000"
        :max="5"
        closable
        container-style="top: 4rem;"
        keep-alive-on-hover
        placement="top-right"
      >
        <NLoadingBarProvider>
          <InitializeMessageInWindow/>
          <slot></slot>
        </NLoadingBarProvider>
      </NMessageProvider>
    </NDialogProvider>
  </AppConfig>
</template>

<script setup lang="ts">
import AppConfig from "@/AppConfig.vue";
import {NMessageProvider, NDialogProvider, NLoadingBarProvider, useMessage, useLoadingBar} from "naive-ui";

const InitializeMessageInWindow = () => {
  window.$message = useMessage();
  window.$loading = useLoadingBar();
}
</script>
