<template>
<div class="leading-4">
  <div class="logo" :class="[ `logo--v${version}`, colorBlend ? 'logo--blend' : '' ]" v-if="dimension" :style="dimension"/>
</div>
</template>

<script setup>
import {computed, toRefs, unref, warn} from "vue";

const props = defineProps({
  version: {
    type: Number,
    default: () => 1,
    validate(value) {
      if (![1,2].includes(value)) {
        warn(`version prop must be either 1 or 2!. ${value} given.`);
        return false;
      }

      return true;
    },
  },
  ratio: {
    type: Number,
    default: 1,
  },
  colorBlend: {
    type: Boolean,
  }
});

const { version, ratio } = toRefs(props);

const dimension = computed(() => {
  switch (unref(version)) {
    case '1':
    case 1: {
      return {
        width: (66 * unref(ratio)) + 'px',
        height: (79 * unref(ratio)) + 'px',
      }
    }
    case '2':
    case 2: {
      return {
        width: (300 * unref(ratio)) + 'px',
        height: (79 * unref(ratio)) + 'px',
      }
    }
    default: {
      return null;
    }
  }
})
</script>

<style scoped lang="scss">
.logo {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: filter 300ms ease-in;
  display: inline-block;
  //max-width: fit-content;

  &--blend {
    mix-blend-mode: color-burn;
  }

  &--v1 {
    background-image: url('@images/logo.png');
    height: 5.5rem;
    width: 100%;
    aspect-ratio: 1;
  }

  &--v2 {
    background-image: url('@images/logo-transparent.png');
    filter: contrast(2) brightness(0.75) saturate(1.5);
  }
}
</style>
